import React, { useEffect, useState } from "react";
import { Input, Table, Tabs, Tag } from "antd";

import { useAppSelector } from "../../../store/hooks";

import DeleteModal from "../../../components/Modals/DeleteModal";
import CreateCategory from "../../../components/Modals/CreateCategory";

import { getAllCategory } from "../../../store/services/category";
import { CategoryType } from "../../../store/interfaces/Category";
import { firstLetterCap } from "../../../utils";
import moment from "moment";

const { Search } = Input;

const Category: React.FC = () => {
  const { isLoading, allCategories } = useAppSelector(
    (state) => state.category
  );
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");
  const [categoryData, setCategoryData] = useState<Array<CategoryType>>([]);
  const [subcategoryData, setSubcategoryData] = useState<Array<CategoryType>>(
    []
  );

  const getData = async () => {
    await getAllCategory();
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setCategoryData(
      allCategories?.filter((v) => v?.categoryType === "category")
    );
    setSubcategoryData(
      allCategories?.filter((v) => v?.categoryType === "subcategory")
    );
  }, [allCategories]);

  const imageColumn = {
    title: " ",
    dataIndex: "imageUrl",
    key: "imageUrl",
    render: (t: string) => (
      <div
        className="users_image_box"
        style={{
          backgroundImage: `url(${
            t ? t : require("../../../assets/images/cart.png")
          })`,
        }}
      ></div>
    ),
  };
  const nameColumn = {
    title: "Name",
    dataIndex: "name",
    key: "name",
  };
  const dateColumn = {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (t: string) => <p>{moment(t).format("MMMM DD, YYYY") || "-"} </p>,
  };
  const mainCategoryColumn = {
    title: "Main Category",
    dataIndex: "categoryId",
    key: "categoryId",
    render: (_: string, obj: any) => {
      console.log("🚀 ~ mainCategoryColumn.obj:", obj);
      return (
        <p>
          {obj?.categoryId?.length
            ? obj?.categoryId &&
              obj?.categoryId?.map((category: CategoryType) => (
                <Tag color="blue"> {firstLetterCap(category?.name)}</Tag>
              ))
            : "-"}
        </p>
      );
    },
  };
  const actionscolumn = {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render: (_: string, obj: CategoryType) => {
      return (
        <div className="icon_box">
          <CreateCategory getData={getData} isEdit editObj={obj} />
          <DeleteModal type={"category"} id={obj?._id} getData={getData} />
        </div>
      );
    },
  };
  const categoryColumns = [imageColumn, dateColumn, nameColumn, actionscolumn];
  const subcategoryColumns = [
    imageColumn,
    dateColumn,
    nameColumn,
    mainCategoryColumn,
    actionscolumn,
  ];

  const filteredCategory = categoryData?.filter((v) =>
    v?.name?.toLowerCase()?.includes(search?.toLowerCase())
  );
  const filteredSubcategory = subcategoryData?.filter(
    (v) =>
      v?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
      v?.categoryId?.name?.toLowerCase()?.includes(search?.toLowerCase())
  );

  const tabItems = [
    {
      label: "Main Category",
      key: "1",
      children: (
        <div
          className="user_table_container category_table"
          style={{ marginTop: 0 }}
        >
          <Table
            loading={isLoading}
            rowKey={(record) => record._id}
            columns={categoryColumns}
            dataSource={filteredCategory}
            scroll={{ x: 600 }}
            pagination={{
              current: page,
              total: filteredCategory?.length,
              pageSize: perPage,
              onChange: (val) => {
                setPage(val);
              },
            }}
          />
        </div>
      ),
    },
    {
      label: "Sub Category",
      key: "2",
      children: (
        <div
          className="user_table_container category_table"
          style={{ marginTop: 0 }}
        >
          <Table
            loading={isLoading}
            rowKey={(record) => record._id}
            columns={subcategoryColumns}
            dataSource={filteredSubcategory}
            scroll={{ x: 600 }}
            pagination={{
              current: page,
              total: filteredSubcategory?.length,
              pageSize: perPage,
              onChange: (val) => {
                setPage(val);
              },
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="user_container">
      <header>
        <h1>Categories</h1>
        <CreateCategory getData={getData} />
      </header>

      <div className="search_input_box">
        <Search
          placeholder="Search by name"
          enterButton
          allowClear
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div style={{ marginTop: "30px" }}>
        <Tabs
          defaultActiveKey="1"
          type="card"
          size={"small"}
          items={tabItems}
          onChange={() => {
            setPage(1);
            setSearch("");
          }}
        />
      </div>
    </div>
  );
};

export default Category;
